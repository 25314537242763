<template>
    <section class="hero is-white is-fullheight">
        <!-- Hero head: will stick at the top -->
        <div class="hero-head">
            <nav class="navbar">
                <div class="container">
                    <div class="navbar-brand">
                        <a class="navbar-item">
<!--                            <img src="/logo.png" alt="Logo">-->
                        </a>
                    </div>
                </div>
            </nav>
        </div>

        <!-- Hero content: will be in the middle -->
        <div class="hero-body">
            <div class="container">
                <router-view></router-view>
            </div>
        </div>

        <!-- Hero footer: will stick at the bottom -->
        <div class="hero-foot">

        </div>
    </section>
</template>

<script>
    export default {
        name: "AuthLayout"
    }
</script>

<style scoped>

</style>
